<script setup>
import HeaderLayout from "@/components/Header/HeaderLayout.vue";
import Loader from "@/components/Loader/Loader.vue";
import {inject} from "vue";
import ConfigMenuView from "@/components/ConfigMenu/ConfigMenuView.vue";
const rootScope = inject('rootScope');
</script>

<template>
  <Loader :loading="rootScope.loading"/>
  <Header-layout/>
  <Transition name="fade">
    <div class="layout-sidebar" v-show="rootScope.toggleMenu">
      <ConfigMenuView />
    </div>
  </Transition>

  <div class="container-fluid margin-container ">
    <router-view/>
  </div>

</template>

<style scoped lang="scss">
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

}
</style>
