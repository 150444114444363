<script setup>

import {requestHttp} from "@/services/HttpRequest";
import {inject, onBeforeUnmount, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import Li from "@/components/ConfigMenu/Li.vue";

const rootScope = inject('rootScope');
const router = useRouter();

const menus = ref([]);

const dropdown = document.getElementsByClassName("dropdown-btn-custom");

onMounted(() => {
  loadMenu()
  setTimeout(() => {
    toggleMenu();
  }, 2000);
})

onBeforeUnmount(() => {
  if (rootScope.toggleMenu) {
    for (const element of dropdown) {
      element.removeEventListener('click');
    }
  }
})

const ontoggleMenu = () => {
  rootScope.toggleMenu = !rootScope.toggleMenu
}
const getMenu = async () => {
  const response = await requestHttp({
    endpoint: 'auth/getMenu',
    method: 'GET'
  });
  return response.data;
};


const fetchMenu = async () => {

  const response = await getMenu();
  if (rootScope.estado === 502) {
    await router.push('/');
  }
  const navMenu = response.data;

  const groups = new Set(navMenu.map(item => item.descripcion));
  let arrayMenu = [];

  groups.forEach(g => {
    let option = {
      name: g,
      values: navMenu.filter(i => i.descripcion === g)
    }
    arrayMenu = [...arrayMenu, option]
  })

  return arrayMenu;
}

const loadMenu = () => {
  fetchMenu().then(data => {
    menus.value = [...data]
  })
}

const toggleMenu = () => {

  for (const element of dropdown) {
    element.addEventListener("click", function () {
      let dropdownContent = this.nextElementSibling;
      console.log(dropdownContent.style.display)
      if (dropdownContent.style.display === "flex") {
        dropdownContent.style.display = "none";
      } else {
        dropdownContent.style.display = "flex";
      }
    });
  }
}



</script>

<template>
  <nav
      class="md:block md:fixed md:top-0 md:bottom-0  md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative  md:overflow-y-auto z-20  py-4 border-right-1 border-gray-200"
      style="width: 330px; left: 0">

    <div class="mt-4 " >
      <button class="p-link layout-menu-button layout-topbar-button" @click="ontoggleMenu">
        <i class="pi pi-bars"></i>
      </button>
    </div>

    <hr class="my-4 md:min-w-full"/>

    <div v-for="(menu, index) in menus" :key="index">
      <button
          class="md:min-w-full  text-info-500 hover:text-info-600  text-base uppercase font-bold block pt-1 pb-4 no-underline focus:outline-none text-left dropdown-btn-custom bg-white border-none">
        <i class="pi pi-chevron-down mr-2" aria-hidden="true"></i>
        {{ menu.name }}
        <hr class="my-4 md:min-w-full"/>

      </button>

      <ul class="md:flex-column md:min-w-full flex-column align-items-start list-none dropdown-container">
        <Li v-for="(value, index) in menu.values" :key="index" :pagina=value.pagina :submenu=value.submenu
            >
        </Li>
      </ul>
    </div>
  </nav>


</template>

<style scoped lang="scss">

</style>
