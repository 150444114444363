import { createApp, reactive } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import '@/assets/styles.scss';

import PrimeVue from 'primevue/config';

import ToastService from 'primevue/toastservice';
import AutoComplete from 'primevue/autocomplete';
import Button from "primevue/button";
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   // optional
import ConfirmDialog from 'primevue/confirmdialog';
import DataTable from 'primevue/datatable';
import DatePicker from 'primevue/calendar';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Row from 'primevue/row';
import Select from 'primevue/selectbutton';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Toast from "primevue/toast";
import Toolbar from 'primevue/toolbar';

//service

import ConfirmationService from 'primevue/confirmationservice';


const app = createApp(App);
app.component('AutoComplete', AutoComplete);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('ConfirmDialog', ConfirmDialog);
app.component('DataTable', DataTable);
app.component('DatePicker', DatePicker);
app.component('Dialog', Dialog);
app.component('InputText', InputText);
app.component('InputNumber', InputNumber);
app.component('Row', Row);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('Select', Select)

app.use(PrimeVue, {ripple: true});
app.use(ToastService);
app.use(ConfirmationService);
app.use(router);


// providers
const state = reactive({
    loading: false,
    logged: false,
    name: '',
    user: 0,
    permissions: {},
    toggleMenu: false,
    userConsult : 0
})

app.provide(/* key */ 'rootScope', /* value */ state);

app.mount('#app')
