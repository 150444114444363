<script setup>
import {inject} from "vue";
import {useToast} from "primevue/usetoast";
import {HTTP_VERBS, requestHttp} from "@/services/HttpRequest";
import router from "@/router";


const rootScope = inject('rootScope');
const toast = useToast();
const ontoggleMenu = () => {
  rootScope.toggleMenu = !rootScope.toggleMenu
}

const logout = async () => {
  try {
    rootScope.loading = true;
    const response = await requestHttp({
      endpoint: 'auth/closeSession',
      method: HTTP_VERBS.GET
    })

    rootScope.loading = false;
    const data = response.data;
    if (data.estado === 200) {
      rootScope.logged = false;
      showMessage('success', data.mensaje)

    } else {
      showMessage('warn', data.mensaje)
    }

  } catch (error) {
    rootScope.loading = false;
    console.log(error)
  }
}

const showMessage = (type, message) => {
  toast.add({severity: type, summary: `Message ${type}`, detail: message, life: 3000});

  if (type === 'success') {
    router.push('/login')
  }
};
</script>

<template>

  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <!--      <img :src="logoUrl" alt="logo" />-->

      <img src="https://images-development-codi.s3.amazonaws.com/postobon-frisby/Logo-frisby-x-hit+1.webp" alt="logo">
    </router-link>

    <button v-show="!rootScope.toggleMenu" class="p-link layout-menu-button layout-topbar-button" @click="ontoggleMenu">
      <i class="pi pi-bars"></i>
    </button>

    <button class="p-link layout-topbar-menu-button layout-topbar-button">
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <div class="layout-topbar-menu layout-topbar-menu-mobile-active">
      <button class="p-link layout-topbar-button">
        <i class="pi pi-user"></i>
        <span>Profile</span>
      </button>
      <button @click="logout()" class="p-link layout-topbar-button">
        <i class="pi pi-sign-out"></i>
        <span>Log Out</span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
