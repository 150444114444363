<script setup>

import {defineProps} from "vue";

const props = defineProps({
  pagina: {
    type: String,
    default: ""
  },
  submenu: {
    type: String,
  }
});

</script>

<template>
  <li class="align-items-start">
    <router-link class="nav-link" :to="props.pagina">
      {{props.submenu}}
      <hr class="my-4 md:min-w-full border-blueGray-100"/>
    </router-link>
  </li>

</template>

<style scoped lang="scss">

</style>
