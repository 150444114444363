import {createRouter, createWebHistory} from 'vue-router'
import MainLayout from "@/layouts/MainLayout.vue";
import {inject} from "vue";
import {requestHttp} from "@/services/HttpRequest";

const routes = [
    {
        path: '/',
        name: 'home',
        component: MainLayout,
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
            },
            {
                path: '/parameters',
                name: 'parameters',
                component: () => import(/* webpackChunkName: "about" */ '../views/Parameter/ParameterView.vue')
            },
            {
                path: '/users',
                name: 'users',
                component: () => import(/* webpackChunkName: "about" */ '../views/User/UserView.vue')
            },
            {
                path: '/user',
                name: 'user',
                component: () => import(/* webpackChunkName: "about" */ '../views/User/ConsultView.vue')
            },

            {
                path: '/prizes',
                name: 'prizes',
                component: () => import(/* webpackChunkName: "about" */ '../views/Prize/PrizeView.vue')
            },
            {
                path: '/ranking',
                name: 'ranking',
                component: () => import(/* webpackChunkName: "about" */ '../views/Ranking/RankingView.vue')
            }, {
                path: '/consult-register',
                name: 'consult-register',
                component: () => import(/* webpackChunkName: "about" */ '../views/Register/RegisterConsultView.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Login/LoginView.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notFount',
        component: () => import(/* webpackChunkName: "about" */ '../views/NotFound/NotFoundView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    const rootScope = inject('rootScope')

    try {

        if (to.name) {
            rootScope.loading = true;
            const response = await requestHttp({
                endpoint: "auth/authStatus",
                body: {
                    route: to.name
                }
            });

            rootScope.loading = false;
            const data = response.data;


            if (data.estado === 400) {
                rootScope.logged = false;
                rootScope.name = "";
                rootScope.user = 0;
                if (to.name !== 'login' && to.name !== 'register') {
                    next({name: 'login'});
                } else next();
            } else if (data.estado === 200) {
                localStorage.setItem('lastPath', to.path);
                const datosSession = data.data;
                rootScope.logged = datosSession.logueado;
                rootScope.name = datosSession.nombre;
                rootScope.user = datosSession.usuario;

                rootScope.permissions = {...datosSession.permissions};

                // if (to.name !== 'password-change' && datosSession.restablecer_clave === 0) {
                //   next({name: 'password-change'});
                // }

                if (to.name === 'login' || to.name === 'register') {
                    next({name: 'home'});
                } else {
                    next();
                }

            } else {
                rootScope.logged = false;
                next({name: 'login'});
            }
        } else {
            next();
        }


    } catch (error) {
        rootScope.logged = false;
        rootScope.loading = false;
        next({name: 'login'});
    }

});
export default router
